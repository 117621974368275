import { EReportTypes, ReportTypeValues, ReportTypes } from "../report/routes/report_types";

export const REPORT_TYPES: ReportTypes = {
  [EReportTypes.FinanceReceiveBills]: {
    name: EReportTypes.FinanceReceiveBills,
    title: "Receive Bills",
    path: "/finance/receive-bills",
  },
  [EReportTypes.FinanceExportBills]: {
    name: EReportTypes.FinanceExportBills,
    title: "Export Bills",
    path: "/finance/export-bills",
  },
  [EReportTypes.FinanceExportedBills]: {
    name: EReportTypes.FinanceExportedBills,
    title: "Exported Bills",
    path: "/finance/exported-bills",
  },
  [EReportTypes.FinanceSalesOrders]: {
    name: EReportTypes.FinanceSalesOrders,
    title: "Sales Orders",
    path: "/finance/sales-orders",
  },
  [EReportTypes.FinanceDepositInvoices]: {
    name: EReportTypes.FinanceDepositInvoices,
    title: "Deposit Invoices",
    path: "/finance/deposit-invoices",
  },
  [EReportTypes.FinanceInvoices]: {
    name: EReportTypes.FinanceInvoices,
    title: "Invoices",
    path: "/finance/invoices",
  },
  [EReportTypes.FinanceExportedInvoices]: {
    name: EReportTypes.FinanceExportedInvoices,
    title: "Exported Invoices",
    path: "/finance/exported-invoices",
  },
};

const BILLING_REPORTS = [
  EReportTypes.FinanceReceiveBills,
  EReportTypes.FinanceExportBills,
  EReportTypes.FinanceExportedBills,
];

const INVOICING_REPORTS = [
  EReportTypes.FinanceSalesOrders,
  EReportTypes.FinanceDepositInvoices,
  EReportTypes.FinanceInvoices,
  EReportTypes.FinanceExportedInvoices,
];

function isBillingReport(report_type: string): boolean {
  return (BILLING_REPORTS as string[]).includes(report_type);
}

function isInvoicingReport(report_type: string): boolean {
  return (INVOICING_REPORTS as string[]).includes(report_type);
}

export function getReportTabs(report_type: string): (keyof typeof REPORT_TYPES)[] {
  if (isBillingReport(report_type)) {
    return BILLING_REPORTS;
  } else if (isInvoicingReport(report_type)) {
    return INVOICING_REPORTS;
  }
  return [];
}

export function getPageTitle(report_type: string): string {
  if (isBillingReport(report_type)) {
    return "Billing";
  } else if (isInvoicingReport(report_type)) {
    return "Invoicing";
  }
  return "";
}
